/*
| Developed by Dirupt
| Filename : error.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import React from 'react'
import Button from '@mui/material/Button'
import ErrorLayout from '@/components/modules/error/ErrorLayout'
import { ErrorPage403, ErrorPage500 } from '@/components/modules/error'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type ErrorProps = {
	error: Error
	reset: () => void
}

/*
|--------------------------------------------------------------------------
| Error component
|--------------------------------------------------------------------------
*/
export default function Error({ error, reset }: ErrorProps) {
	React.useEffect(() => {
		console.error(error)
	})

	// Error 403
	if (error.message === '403') {
		return <ErrorPage403 />
	}

	// Error 500
	if (error.message === '500') {
		return <ErrorPage500 />
	}

	return (
		<ErrorLayout
			maxWidth={'lg'}
			title={'Une erreur est survenue.'}
			description={error?.message ?? 'Veuillez réessayer plus tard.'}
			descriptionProps={{
				sx: {
					mb: 2,
				},
			}}
			renderButton={
				<Button size={'large'} variant={'contained'} onClick={reset} aria-label={'Recharger la page'}>
					{'Recharger la page'}
				</Button>
			}
		/>
	)
}
